import {
  Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Typography, ListItemText,
} from '@material-ui/core';
import { FormState, useFormState, useSendEmail } from '@lib';

import { Alert, Skeleton } from '@material-ui/lab';
import { Formik } from 'formik';
import Link from '@components/atoms/Link';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Select from '@components/atoms/Select';
import TextInput from '@components/atoms/TextInput';
import { gql, useLazyQuery } from '@apollo/client';
import { create } from 'xmlbuilder2';
import { formikInjectedPropsTypes } from '@forms/propTypes';
import { trackEvent } from '@components/organisms/Tracking';
import { useTranslation } from 'react-i18next';
import FORM_INITIAL_VALUES from './initialValues';
import validationSchema from './validate';
import { useStyles } from './styles';

export default function ModelRequestForm(props) {
  const {
    message,
    formFields,
    branch,
    vehicle,
  } = props;
  const { t } = useTranslation();

  const [getBranches, { error, data: branchesData, loading }] = useLazyQuery(FETCH_BRANCHES);

  useEffect(() => {
    if (!branch) {
      getBranches({
        notifyOnNetworkStatusChange: true,
      });
    }
  }, []);

  if (error) {
    throw error;
  }

  const classes = useStyles();
  const { formState, handleGraphQlResult, resetFormState } = useFormState();
  const sendEmail = useSendEmail();

  const handleSubmit = async (values) => {
    try {
      const xmlDoc = generateXml(values, vehicle);

      let data = {
        to: values.branchEmail,
        bcc: ['info@dekay.dev', 'katrin.goerlitz@schimmel-automobile.de'],
        templateId: 'd-10b9b7071c3548c188bfe2516846dbec',
        dynamicTemplateData: {
          ...values,
          url: window.location.href,
          requestTestDrive: values.newField.includes('requestTestDrive') ? 'Ja' : 'Nein',
          requestFinancingOffer: values.newField.includes('requestFinancingOffer') ? 'Ja' : 'Nein',
          requestLeasingOffer: values.newField.includes('requestLeasingOffer') ? 'Ja' : 'Nein',
          requestTradeInOffer: values.newField.includes('requestTradeInOffer') ? 'Ja' : 'Nein',
        },
        attachments: [
          {
            content: window.btoa(xmlDoc.toString()),
            filename: 'dealerdesk.xml',
            type: 'application/xml',
            disposition: 'attachment',
          },
        ],
      };

      let result = await sendEmail(data);

      data = {
        to: values.email,
        bcc: ['info@dekay.dev', 'katrin.goerlitz@schimmel-automobile.de'],
        templateId: 'd-950951649ce1442c964c788a928c2e42',
        dynamicTemplateData: {
          ...values,
          url: window.location.href,
          requestTestDrive: values.newField.includes('requestTestDrive') ? 'Ja' : 'Nein',
          requestFinancingOffer: values.newField.includes('requestFinancingOffer') ? 'Ja' : 'Nein',
          requestLeasingOffer: values.newField.includes('requestLeasingOffer') ? 'Ja' : 'Nein',
          requestTradeInOffer: values.newField.includes('requestTradeInOffer') ? 'Ja' : 'Nein',
        },
      };

      result = await sendEmail(data);
      handleGraphQlResult(result);

      trackEvent('Lead', { content_name: 'Fahrzeuganfrage' });
    } catch (e) {
      console.error(e);
    }
  };

  switch (formState) {
    case FormState.INITIAL:
      return (
        <Formik
          initialValues={{
            ...FORM_INITIAL_VALUES,
            message: message ?? '',
            branchEmail: branch?.attributes.primaryEmail ?? '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikBag) => (
            <Form
              branch={branch}
              branches={branchesData?.branches.data ?? []}
              classes={classes}
              formFields={formFields}
              loading={loading}
              t={t}
              {...formikBag}
            />
          )}
        </Formik>
      );
    case FormState.SUCCESS:
      return (
        <Box my={3}>
          <Alert
            action={(
              <Button onClick={resetFormState}>
                {t('forms.ModelRequestForm.successAlert.newRequest')}
              </Button>
            )}
            severity="success"
          >
            {t('forms.ModelRequestForm.successAlert.content')}
          </Alert>
        </Box>
      );
    case FormState.ERROR:
      return (
        <div>
          Error
        </div>
      );
    default: {
      return null;
    }
  }
}

ModelRequestForm.propTypes = {
  message: PropTypes.string,
  formFields: PropTypes.object,
};
ModelRequestForm.defaultProps = {
  message: null,
  formFields: null,
};

function Form(props) {
  const {
    t,
    classes,
    formFields,
    branches,
    branch,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    dirty,
    errors,
    touched,
    isValid,
    loading,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      {loading && !values.branchEmail ? (
        <Box my={3}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <>
          <Box my={3}>
            <Grid container spacing={5}>
              <Grid item md={5} xs={12}>
                <Box mt={2}>
                  <FormControl
                    color="primary"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="branches">
                      {t('forms.ModelRequestForm.selectLocation')}
                    </InputLabel>
                    <Select
                      labelId="branches"
                      onChange={(e) => setFieldValue('branchEmail', e.target.value)}
                      readOnly={Boolean(branch)}
                      style={branch ? { pointerEvents: 'none' } : null}
                      value={values.branchEmail}
                    >
                      {([...branches, branch]).map((item) => (
                        <MenuItem key={item?.id} value={item?.attributes.primaryEmail}>
                          {item?.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={4}>
                  <FormControl
                    color="primary"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="form-salutation">
                      {t('forms.ModelRequestForm.salutation')}
                    </InputLabel>
                    <Select
                      labelId="form-salutation"
                      onChange={(e) => setFieldValue('saluation', e.target.value)}
                      value={values.saluation}
                    >
                      <MenuItem value="Frau">
                        {t('forms.ModelRequestForm.woman')}
                      </MenuItem>
                      <MenuItem value="Herr">
                        {t('forms.ModelRequestForm.mr')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <TextInput
                    error={errors.name && touched.name}
                    fullWidth
                    helperText={errors.name}
                    label={t('forms.ModelRequestForm.name')}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    error={errors.email && touched.email}
                    fullWidth
                    helperText={errors.email}
                    label={t('forms.ModelRequestForm.email')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                </Box>
                <Box mt={2}>
                  <TextInput
                    error={errors.phone && touched.phone}
                    fullWidth
                    helperText={errors.phone}
                    label={t('forms.ModelRequestForm.phone')}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                  />
                </Box>
                {(formFields?.colors || formFields?.equipmentLines)
                  ? (
                    <>
                      {formFields.colors && (
                        <Box mt={4}>
                          <FormControl
                            color="primary"
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="form-color">
                              {t('forms.ModelRequestForm.color')}
                            </InputLabel>
                            <Select
                              labelId="form-color"
                              onChange={(e) => setFieldValue('color', e.target.value)}
                              value={values.color}
                            >
                              {formFields.colors
                                .split('\n')
                                .map((value) => value.trim())
                                .map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {formFields.equipmentLines && (
                        <Box mt={4}>
                          <FormControl
                            color="primary"
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="form-equipmentLine">
                              {t('forms.ModelRequestForm.equipment')}
                            </InputLabel>
                            <Select
                              labelId="form-equipmentLine"
                              onChange={(e) => setFieldValue('equipmentLine', e.target.value)}
                              value={values.equipmentLine}
                            >
                              {formFields.equipmentLines
                                .split('\n')
                                .map((value) => value.trim())
                                .map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box mt={2}>
                      <FormControl
                        color="primary"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel id="new-field">
                          {t('forms.ModelRequestForm.newOptions.label')}
                        </InputLabel>
                        <Select
                          labelId="new-field"
                          multiple
                          onChange={(e) => setFieldValue('newField', e.target.value)}
                          renderValue={(selected) => NEW_FIELD_NAMES
                            .filter((field) => selected.indexOf(field.value) > -1)
                            .map((field) => t(`forms.ModelRequestForm.newOptions.${field.name}`))
                            .join(', ')}
                          value={values.newField}
                        >
                          {NEW_FIELD_NAMES.map((field) => (
                            <MenuItem key={field.name} value={field.value}>
                              <Checkbox
                                checked={values.newField.indexOf(field.value) > -1}
                                color="primary"
                              />
                              <ListItemText primary={t(`forms.ModelRequestForm.newOptions.${field.name}`)} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
              </Grid>
              <Grid item md={7} xs={12}>
                <Box>
                  <TextInput
                    error={errors.message && touched.message}
                    fullWidth
                    helperText={errors.message}
                    inputProps={{
                      className: classes.messageInput,
                    }}
                    label={t('forms.ModelRequestForm.message')}
                    multiline
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message}
                  />
                </Box>
                <Box mt={2}>
                  {/* <FormControlLabel
                label={
                  <Typography variant="caption">
                    Mit Art, Umfang und Zweck der Erhebung, Verwendung und
                    Berabeitung meiner personenbezogenen Daten gemäß der
                    Datenschutzerklärung erkläre ich mich einverstanden und willige darin ein.
                  </Typography>
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={values.isTermsAndPrivacyAccepted}
                    onChange={(e) => setFieldValue('isTermsAndPrivacyAccepted', e.target.checked)}
                  />
                }
              /> */}
                  <Typography>
                    {t('forms.ModelRequestForm.dataProtectionText')}
                    {' '}
                    <Link href="/datenschutz" legacyBehavior>
                      <a target="_blank">
                        {t('forms.ModelRequestForm.dataProtectionLink')}
                      </a>
                    </Link>
                    {' '}
                    {t('forms.ModelRequestForm.dataProtectionText2')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="center" pt={3}>
            <Button
              color="primary"
              disabled={!dirty || !isValid || isSubmitting}
              fullWidth
              style={{ maxWidth: 300 }}
              type="submit"
              variant="contained"
            >
              {t('forms.ModelRequestForm.sending')}
            </Button>
          </Box>
        </>
      )}
    </form>
  );
}
Form.propTypes = {
  ...formikInjectedPropsTypes,
};

const FETCH_BRANCHES = gql`
query FetchBranches {
  branches(sort: "name:ASC") {
    data {
      id
      attributes {
        name
        primaryEmail
      }
    }
  }
}
`;

const generateXml = (values, vehicle) => {
  // eslint-disable-next-line no-nested-ternary
  const salution = values.saluation ? values.saluation === 'Frau' ? 'MS' : 'MR' : '';
  const doc = create({ version: '1.0', encoding: 'UTF-8' })
    .ele('dealerdeskLead')
    .ele('generalInformation')
    .ele('type')
    .txt('OPPORTUNITY')
    .up()
    .ele('subject')
    .txt('Individuelle Anfrage')
    .up()
    .ele('channel')
    .txt('WEBSITE')
    .up()
    .ele('dealerId')
    .txt(vehicle?.attributes.branch?.data?.id)
    .up()
    .ele('sourceId')
    .up()
    .ele('contextLink')
    .up()
    .ele('urgency')
    .txt('NORMAL')
    .up()
    .ele('preferredContactMethod')
    .up()
    .ele('preferredContactDetails')
    .up()
    .ele('earliestContactTime')
    .up()
    .ele('latestContactTime')
    .up()
    .ele('externalReference')
    .up()
    .ele('escalationGroupId')
    .up()
    .up()
    .ele('contact')
    .ele('status')
    .txt('PROSPECT')
    .up()
    .ele('type')
    .txt('PRIVATE')
    .up()
    .ele('salutation')
    .txt(salution)
    .up()
    .ele('dateOfBirth')
    .up()
    .ele('companyName')
    .up()
    .ele('fullName')
    .txt(values.name)
    .up()
    .ele('namePrefix')
    .up()
    .ele('givenName')
    .txt(values.name.split(' ')[0])
    .up()
    .ele('familyName')
    .txt(values.name.split(' ')[1])
    .up()
    .ele('address1')
    .up()
    .ele('address2')
    .up()
    .ele('zip')
    .up()
    .ele('city')
    .up()
    .ele('state')
    .up()
    .ele('country')
    .up()
    .ele('phone')
    .txt(values.phone)
    .up()
    .ele('email')
    .txt(values.email)
    .up()
    .ele('externalReference')
    .up()
    .up()
    .ele('requestedVehicle')
    .ele('internalId')
    .txt(vehicle?.attributes.vehicleId)
    .up()
    .ele('link')
    .txt(window.location.href)
    .up()
    .ele('vehicleClass')
    .txt('CAR')
    .up()
    .ele('make')
    .txt(vehicle?.attributes.brand)
    .up()
    .ele('model')
    .txt(vehicle?.attributes.baselineModel)
    .up()
    .ele('modelDescription')
    .txt(vehicle?.attributes.model)
    .up()
    .ele('vin')
    .txt(vehicle?.attributes.vehicleIdentificationNumber)
    .up()
    .ele('usageType')
    .txt(vehicle?.attributes.isNewCar ? 'NEW' : 'USED')
    .up()
    .ele('mileage')
    .txt(vehicle?.attributes.mileage)
    .up()
    .ele('price')
    .txt(vehicle?.attributes.price)
    .up()
    .ele('firstRegistration')
    .txt(vehicle?.attributes.initialRegistrationDate)
    .up()
    .ele('fuel')
    .txt(FUEL_TYPE[vehicle?.attributes.fuelType])
    .up()
    .ele('power')
    .txt(vehicle?.attributes.power)
    .up()
    .ele('exteriorColor')
    .up()
    .ele('cubicCapacity')
    .txt(vehicle?.attributes.ccm)
    .up()
    .ele('preOffer')
    .up()
    .up()
    .ele('acquisition')
    .ele('tradeInRequested')
    .up()
    .ele('registrationRequested')
    .up()
    .ele('insuranceRequested')
    .up()
    .ele('deliveryRequested')
    .up()
    .ele('testdriveRequested')
    .txt(values.requestTestDrive ? '1' : '0')
    .up()
    .ele('preferredTimeOfTestdrive')
    .up()
    .ele('counterOffer')
    .up()
    .ele('dealName')
    .up()
    .ele('acquisitionType')
    .up()
    .ele('paybackPeriodMonths')
    .up()
    .ele('totalMileage')
    .up()
    .ele('firstInstallment')
    .up()
    .ele('monthlyInstallment')
    .up()
    .ele('finalInstallment')
    .up()
    .up()
    .ele('link')
    .ele('url')
    .txt(window.location.href)
    .up()
    .ele('description')
    .txt('Link zur Website')
    .up()
    .ele('key')
    .txt('csb-schimmel-atuomobile')
    .up()
    .up()
    .end({ prettyPrint: false });

  return doc;
};

const FUEL_TYPE = {
  other: 'OTHER',
  gasoline: 'PETROL',
  diesel: 'DIESEL',
  lpg: 'LPG',
  naturalGas: 'CNG',
  electric: 'ELECTRICITY',
  hybrid: 'HYBRID',
  hydrogen: 'HYDROGENIUM',
  ethanol: 'ETHANOL',
  hybridDiesel: 'HYBRID_DIESEL',
};

const NEW_FIELD_NAMES = [
  { name: 'requestTestDrive', value: 'requestTestDrive' },
  { name: 'requestFinancingOffer', value: 'requestFinancingOffer' },
  { name: 'requestLeasingOffer', value: 'requestLeasingOffer' },
  { name: 'requestTradeInOffer', value: 'requestTradeInOffer' },
];
