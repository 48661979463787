/* eslint-disable no-nested-ternary */
import {
  Box, Button, Divider, Grid, Hidden, Link, Paper, Tooltip, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Image from '@components/atoms/Image';
import { FiMail, FiPhone } from 'react-icons/fi';
import { MediaElement } from '@plugins/next-cms-core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import twemoji from 'twemoji';
import { get } from 'lodash';
import { trackEvent } from '@components/organisms/Tracking';

export default function StaffMember(props) {
  const {
    employee, variant, inventoryCar, onShowRequestForm,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isHorizontal = variant === 'horizontal';

  return (
    <Paper style={{ height: '100%' }} variant="outlined">
      <Grid
        alignItems="center"
        style={{ display: isHorizontal ? 'flex' : 'inline-block' }}
      >
        <Grid
          item
          md={isHorizontal && !inventoryCar ? 6 : inventoryCar ? 3 : 12}
          sm={inventoryCar ? 3 : 12}
          style={{ paddingRight: !inventoryCar ? 0 : 15 }}
          xs={12}
        >
          {get(employee?.attributes, 'image') && (
            <MediaElement
              alt={employee?.attributes.name}
              className={
                isHorizontal
                  ? classes.mediaItemHorizontal
                  : classes.mediaItemVertical
              }
              data={employee?.attributes.image}
              isFluid
            />
          )}
          {!get(employee?.attributes, 'image') && inventoryCar && (
            <Image
              alt="Ihre online Verkaufsberater"
              className={
                isHorizontal
                  ? classes.mediaItemHorizontal
                  : classes.mediaItemVertical
              }
              isFluid
              src="/images/people/online-sales-team-v2.jpg"
            />
          )}
        </Grid>
        <Grid
          item
          md={isHorizontal && !inventoryCar ? 6 : inventoryCar ? 9 : 12}
          sm={inventoryCar ? 9 : 12}
          style={{
            textAlign: !inventoryCar ? 'center' : '',
            padding: !inventoryCar ? 15 : 0,
          }}
          xs={12}
        >
          {get(employee?.attributes, 'primary_branch.data.attributes.name') && (
            <Typography variant="caption">
              {t('components.molecules.StaffMember.location')}
              {' '}
              <Link
                className={classes.branchLink}
                href={employee.attributes.primary_branch.data.attributes.pageUrl}
              >
                {employee.attributes.primary_branch.data.attributes.name}
              </Link>
            </Typography>
          )}
          <Grid
            alignItems="center"
            container
            justifyContent={!inventoryCar ? 'center' : ''}
            spacing={1}
          >
            <Grid item xs="auto">
              <Typography component="h4" variant="subtitle2">
                {get(employee?.attributes, 'name', 'CSB Schimmel Automobile GmbH')}
              </Typography>
            </Grid>
            {get(employee?.attributes, 'languages.data', [])?.length > 0 && (
              <Grid item xs="auto">
                <Grid alignItems="center" container>
                  {employee.attributes.languages.data.map((language) => (
                    <Tooltip key={language.id} title={language.attributes.name}>
                      <span
                        className={classes.emojiWrapper}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: twemoji.parse(language.attributes.emoji, {
                            base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
                          }),
                        }}
                      />
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
          {(get(employee?.attributes, 'position') || inventoryCar) && (
            <Typography component="h4" variant="body2">
              {get(employee?.attributes, 'position', 'Hyundai- & Mitsubishi-Vertragshändler')}
            </Typography>
          )}
          {inventoryCar && (
            <Box my={1}>
              <Typography variant="body2">
                {inventoryCar.attributes.branch.data.attributes.addressStreet}
                <br />
                {inventoryCar.attributes.branch.data.attributes.addressZipcode}
                {' '}
                {inventoryCar.attributes.branch.data.attributes.addressCity}
              </Typography>
            </Box>
          )}
          {!inventoryCar && (
            <Box my={2}>
              <Divider />
            </Box>
          )}
          <Box alignItems="center" display={inventoryCar ? 'flex' : 'inline-block'}>
            {inventoryCar && (
              <Box mr={inventoryCar ? 1 : 0} mt={1}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={onShowRequestForm}
                  size="small"
                  startIcon={<FiMail />}
                  variant="outlined"
                >
                  {t('components.molecules.StaffMember.requests')}
                </Button>
              </Box>
            )}
            {(get(employee?.attributes, 'phoneWhatsapp')) && (
              <Box mr={inventoryCar ? 1 : 0} mt={1}>
                <Button
                  component="a"
                  fullWidth
                  href={`https://wa.me/${get(employee.attributes, 'phoneWhatsapp').replace('+', '')}`}
                  size="small"
                  startIcon={<WhatsappIcon />}
                  target="_blank"
                >
                  <Hidden smDown>
                    {get(employee?.attributes, 'phoneWhatsapp')}
                  </Hidden>
                  <Hidden mdUp>
                    Whatsapp
                  </Hidden>
                </Button>
              </Box>
            )}
            {(get(employee?.attributes, 'phone') || inventoryCar) && (
              <Box mr={inventoryCar ? 1 : 0} mt={1}>
                <Button
                  component="a"
                  fullWidth
                  href={`tel:${get(employee?.attributes, 'phone', inventoryCar?.attributes.branch.data.attributes.salesPhone)}`}
                  onClick={() => {
                    trackEvent('Contact');
                    trackEvent('ContactCustom', { channel: 'Phone', origin: 'Employee' });
                  }}
                  size="small"
                  startIcon={<FiPhone />}
                >
                  <Hidden smDown>
                    {get(employee?.attributes, 'phone', inventoryCar?.attributes.branch.data.attributes.salesPhone)}
                  </Hidden>
                  <Hidden mdUp>
                    {t('components.molecules.StaffMember.phone')}
                  </Hidden>
                </Button>
              </Box>
            )}
            {(get(employee?.attributes, 'email') || inventoryCar) && (
              <Box mr={inventoryCar ? 1 : 0} mt={1}>
                <Button
                  component="a"
                  fullWidth
                  href={`mailto:${get(employee?.attributes, 'email', inventoryCar?.attributes.branch.data.attributes.primaryEmail)}`}
                  onClick={() => {
                    trackEvent('Contact');
                    trackEvent('ContactCustom', { channel: 'Email', origin: 'Employee' });
                  }}
                  size="small"
                  startIcon={<FiMail />}
                >
                  <Hidden smDown>
                    {t('components.molecules.StaffMember.writeEmail')}
                  </Hidden>
                  <Hidden mdUp>
                    {t('components.molecules.StaffMember.email')}
                  </Hidden>
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  mediaItemHorizontal: {
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  mediaItemVertical: {
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  emojiWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
    '& img': {
      height: theme.spacing(2),
      width: theme.spacing(2),
      margin: '.05em .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },

  branchLink: {
    color: theme.palette.primary.main,
  },
}));

StaffMember.defaultProps = {
  variant: 'horizontal',
  inventoryCar: null,
  onShowRequestForm: () => {},
};

StaffMember.propTypes = {
  employee: PropTypes.object.isRequired,
  inventoryCar: PropTypes.object,
  onShowRequestForm: PropTypes.func,
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
};

function WhatsappIcon() {
  return (
    <svg
      height="18"
      viewBox="0 0 425 425"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M361,62.38A207.17,207.17,0,0,0,213.47,1.22C98.52,1.22,5,94.77,4.92,209.76A208.16,208.16,0,0,0,32.76,314L3.17,422.09l110.56-29a208.28,208.28,0,0,0,99.66,25.38h.09c114.94,0,208.5-93.56,208.55-208.55A207.29,207.29,0,0,0,361,62.38ZM213.48,383.25h-.07a173.08,173.08,0,0,1-88.23-24.16l-6.33-3.76L53.24,372.54l17.51-64L66.63,302a173,173,0,0,1-26.5-92.25c0-95.57,77.8-173.33,173.42-173.33A173.37,173.37,0,0,1,386.82,209.91C386.78,305.49,309,383.25,213.48,383.25Zm95.08-129.82c-5.21-2.61-30.83-15.21-35.61-17s-8.25-2.6-11.72,2.61-13.46,17-16.5,20.44-6.08,3.91-11.29,1.3-22-8.11-41.91-25.87c-15.49-13.81-25.95-30.88-29-36.09s-.32-8,2.28-10.64c2.35-2.34,5.22-6.09,7.82-9.13s3.48-5.22,5.21-8.69.87-6.52-.43-9.13-11.73-28.26-16.07-38.7c-4.23-10.16-8.52-8.78-11.72-8.94-3-.15-6.52-.18-10-.18a19.15,19.15,0,0,0-13.9,6.52c-4.77,5.21-18.24,17.82-18.24,43.47s18.68,50.43,21.28,53.91,36.75,56.11,89,78.68a299.53,299.53,0,0,0,29.71,11c12.48,4,23.84,3.41,32.82,2.06,10-1.49,30.83-12.6,35.18-24.77s4.34-22.61,3-24.78S313.77,256,308.56,253.43Z"
          fill="#25d366"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
