import {
  Box, Button, Paper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useConsentManager } from '@lib/gdpr';
import Container from '../atoms/Container';

export default function ConsentRequiredFallback() {
  const { changeConsent } = useConsentManager();
  const classes = useStyles();

  const handleChangeConsent = () => {
    changeConsent();
  };
  return (
    <Container>
      <Paper
        className={classes.root}
        variant="outlined"
      >
        <Typography gutterBottom variant="h6">
          Zustimmung für externe Inhalte erforderlich
        </Typography>
        <Typography variant="body2">
          Um diesen Inhalt anzeigen zu können, ist Ihre Zustimmung erforderlich.
        </Typography>
        <Box mt={3}>
          <Button
            color="primary"
            component="a"
            onClick={handleChangeConsent}
            variant="contained"
          >
            Datenschutz-Zustimmung anpassen
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
