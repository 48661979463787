import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
  },
  messageInput: {
    height: '300px !important',
  },
}));
