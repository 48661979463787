import React from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function TextInput(props) {
  const { InputProps, multiline } = props;
  const classes = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        className: clsx(InputProps?.className, {
          [classes.multiline]: Boolean(multiline),
        }),
      }}
    />
  );
}

TextInput.propTypes = {
  ...TextField.propTypes,
};
TextInput.defaultProps = {
  ...TextField.defaultProps,
};

const useStyles = makeStyles(() => ({
  multiline: {
    paddingTop: 7,
  },
}));
